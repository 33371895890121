<template>
  <MainCard>
    <MainCardHeader>
      <div class="card-header-row">
        <div class="card-title">
          Target Prospects
          <InfoBox
            text="New business that you have identified for potential future sales yet have not begun any active conversations. You do not expect sales within the next 30 days. Value should be an estimate of a first sale to the potential business. 10% of the value is added to your pipeline."
          />
        </div>
        <SearchField
          @search="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
      </div>
      <CardTableHeader
        :isCheckboxShown="true"
        :isCompanyShown="true"
        :isValueShown="true"
        :isDealsStatusShown="true"
        :isSelectAllCheckbox="isSelectAllCheckbox"
        :selectedRows="selectedRows"
        :order="order"
        @checkboxSelect="selectAllRows"
        @removeSelection="removeRowsSelection"
        @setOrder="setOrder"
      />
    </MainCardHeader>
    <DividerLine />
    <div
      class="deals-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <draggable
        v-model="currentPage"
        handle=".drag-handle"
        ghost-class="ghost"
        chosen-class="chosen-item"
        :disabled="order !== '' || isDragDisabled"
        @end="handleDragEnd"
        item-key="id"
      >
        <template #item="{ element }">
          <PipelineCardRow
            :key="element.id"
            :id="element.id"
            :order="order"
            :isCompanyEditable="true"
            :isNameEditable="true"
            :name="element.subject_name"
            :company="element.subject_company"
            :dealValue="element.value"
            :selectedRows="selectedRows"
            :isDealClosed="element.is_closed"
            :menuActions="Object.values(menuActions)"
            :actionMenuWidth="'282px'"
            :actionMenuHeight="'245px'"
            :actionMenuTop="'45px'"
            :isSubscriptionActive="isSubscriptionActive"
            :isOutOfReportRow="isItemOutOfReport(element.id)"
            @updateName="
              handleDealSubjectUpdate({ subject_name: $event }, element)
            "
            @updateCompany="
              handleDealSubjectUpdate({ subject_company: $event }, element)
            "
            @changeDealClosedState="
              handleDealStatusChange(
                element.is_closed,
                element.value,
                element.id
              )
            "
            @updateDealValue="handleDealValueUpdate($event, element.id)"
            @checkboxSelect="selectOneRow(element.id)"
            @removeSelection="removeOneRowSelection(element.id)"
            @actionMenuItemClick="handleActionMenuClick($event, element)"
          />
        </template>
      </draggable>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

import MainCard from '../../../common/mainContent/MainCard.vue'
import MainCardHeader from '../../../common/mainContent/MainCardHeader.vue'
import CardTableHeader from '../../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../../common/DividerLine.vue'
import SearchField from '../../../common/header/SearchField.vue'
import Spinner from '../../../common/Spinner.vue'
import InfoBox from '../../../common/InfoBox.vue'
import CardFooter from '../../../common/mainContent/CardFooter.vue'
import ActionMenu from '../../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../../common/actionMenu/ActionMenuItem.vue'
import PipelineCardRow from '../PipelineCardRow.vue'

import { DealType } from '../../../../utils/constants'

export default {
  props: [
    'selectedRows',
    'isSubscriptionActive',
    'shouldUpdateTargetList',
    'shouldResetTargetOrder',
  ],
  components: {
    draggable,
    MainCard,
    DividerLine,
    CardTableHeader,
    SearchField,
    MainCardHeader,
    Spinner,
    CardFooter,
    ActionMenu,
    ActionMenuItem,
    PipelineCardRow,
    InfoBox,
  },
  data() {
    return {
      isLoading: true,
      pageSize: 'All',
      currentPageNumber: 1,
      searchTerm: '',
      order: '',
      isDragDisabled: false,
      isActionMenuShown: false,
      errorMessage: '',
      isSelectAllCheckbox: false,
      menuActions: {
        MOVE_TO_CLIENTS: 'Move to Clients',
        MOVE_TO_HOT: 'Move to Hot',
        MOVE_TO_IN_PROGRESS: 'Move to in Progress',
        REMOVE_FROM_PIPELINE: 'Remove from Pipeline',
        ARCHIVE: 'Archive',
      },
      dealsType: DealType,
      RowsPerPage: ['All', 10, 20, 50],
    }
  },
  async created() {
    await this.getFirstPage()
  },
  computed: {
    ...mapState(['targetDeals']),
    targetDealsList() {
      return this.targetDeals.targetDealsList
    },
    currentPage: {
      get() {
        if (this.pageSize === 'All') return this.targetDealsList

        const start =
          this.currentPageNumber === 1
            ? 0
            : this.currentPageNumber * this.pageSize - this.pageSize

        const stop =
          this.currentPageNumber === 1
            ? this.pageSize
            : this.currentPageNumber * this.pageSize

        const page = this.targetDealsList.slice(start, stop)

        return page
      },
      set(value) {},
    },
    totalItemsCount() {
      return this.targetDeals.targetDealsCount
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All') return this.targetDealsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    async shouldUpdateTargetList(newValue, _) {
      if (!newValue) return

      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearTargetDeals()
      await this.getFirstPage()

      this.$emit('targetListUpdated')
    },
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000)
      }
    },
    async order() {
      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearTargetDeals()
      await this.getFirstPage()
    },
    shouldResetTargetOrder(newValue, _) {
      if (!newValue) return

      this.order = ''
      this.$emit('targetOrderResetted')
    },
  },
  methods: {
    ...mapActions([
      'getNextTargetDealsPage',
      'updateDealsOrder',
      'updateProspectDealSubject',
      'updateTargetDealValue',
      'openTargetDeal',
      'closeTargetDeal',
      'moveTargetDealToHot',
      'moveTargetDealToInProgress',
      'removeTargetDeals',
      'searchTargetPropspectDeals',
      'clearTargetDeals',
      'movePipelineProspectsToClients',
      'archiveProspects',
    ]),
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.clearTargetDeals()
      await this.getFirstPage()
    },
    async getFirstPage() {
      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.TargetProspectDeal,
        }
        await this.getNextTargetDealsPage(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue
      this.currentPageNumber = 1
      const payload = {
        order: this.order,
        searchTerm: searchValue,
        pageSize: this.pageSize,
        pageNumber: this.currentPageNumber,
        dealsType: this.dealsType.TargetProspectDeal,
      }

      try {
        await this.searchTargetPropspectDeals(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    setOrder(evt) {
      if (this.isLoading) return
      this.order = evt
    },
    async handleDragEnd(evt) {
      this.isDragDisabled = true

      const { oldIndex, newIndex } = evt

      if (oldIndex === newIndex) return

      const elementId = this.currentPage[oldIndex].id

      try {
        await this.updateDealsOrder({
          elementId,
          oldLocalIndex: oldIndex,
          newLocalIndex: newIndex,
          dealsType: this.dealsType.TargetProspectDeal,
        })
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isDragDisabled = false
      }
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.currentPageNumber += 1
      this.$emit('removeAllDealsSelection')

      if (this.currentPage.length) return

      this.isLoading = true

      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.TargetProspectDeal,
        }
        await this.getNextTargetDealsPage(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.$emit('removeAllDealsSelection')
      this.currentPageNumber -= 1
    },
    isItemOutOfReport(itemId) {
      const itemIndex = this.targetDealsList.findIndex((i) => i.id === itemId)

      if (itemIndex > 29) return true

      return false
    },
    selectAllRows() {
      const ids = this.currentPage.map((item) => item.id)
      this.$emit('selectAllDeals', ids)
    },
    removeRowsSelection() {
      this.$emit('removeAllDealsSelection')
    },
    selectOneRow(rowId) {
      this.$emit('selectOneDeal', rowId)
      if (this.selectedRows.length === this.currentPage.length) {
        this.isSelectAllCheckbox = true
      }
    },
    removeOneRowSelection(rowId) {
      this.$emit('removeOneDealSelection', rowId)
      if (this.selectedRows.length < this.currentPage.length) {
        this.isSelectAllCheckbox = false
      }
    },
    handleDealSubjectUpdate(dealSubjectUpdate, deal) {
      try {
        const updatedDeal = { ...deal, ...dealSubjectUpdate }
        this.updateProspectDealSubject(updatedDeal)
      } catch (error) {
        this.handleGeneralError(error)
      }
    },
    async handleDealValueUpdate(newDealValue, dealId) {
      const dealUpdate = { value: Number(newDealValue), id: dealId }
      await this.updateTargetDealValue(dealUpdate)
    },
    async handleDealStatusChange(isDealClosed, dealValue, dealId) {
      const payload = { id: dealId, value: Number(dealValue) }

      if (isDealClosed) {
        await this.openTargetDeal(payload)
      } else {
        await this.closeTargetDeal(payload)
      }
    },
    async handleActionMenuClick(action, deal) {
      this.isActionMenuShown = false
      this.isLoading = true

      const payload = {
        prospectId: deal.subject,
        dealId: deal.id,
        name: deal.subject_name,
        company: deal.subject_company,
      }

      try {
        if (action === this.menuActions.MOVE_TO_CLIENTS) {
          await this.movePipelineProspectsToClients([deal.subject])
        }

        if (action === this.menuActions.MOVE_TO_HOT) {
          payload.status = this.dealsType.HotProspectDeal
          await this.moveTargetDealToHot(payload)
        }

        if (action === this.menuActions.MOVE_TO_IN_PROGRESS) {
          payload.status = this.dealsType.InProgressProspectDeal
          await this.moveTargetDealToInProgress(payload)
        }

        if (action === this.menuActions.REMOVE_FROM_PIPELINE) {
          await this.removeTargetDeals([deal.id])
          this.$emit('updateProspectsList')
        }

        if (action === this.menuActions.ARCHIVE) {
          await this.removeTargetDeals([deal.id])
          await this.archiveProspects([deal.subject])

          this.$emit('updateProspectsList')
        }

        this.currentPageNumber = 1

        await this.clearTargetDeals()
        await this.getFirstPage()

        this.removeRowsSelection()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.main-card {
  height: 655px;
  min-height: 655px;

  .card-header-row {
    width: calc(100% - 80px);
    height: calc(100% - 47px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
      font-size: $fontSizeTitle;
    }
  }
}

@media (max-width: 420px) {
  .card-footer {
    width: 980px;
  }
  .card-footer:deep(.rows-per-page) {
    margin-left: 340px !important;
  }
  .divider-line {
    width: 980px;
  }
  .deals-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    overflow-y: visible;
  }
  .card-table-header {
    width: calc(100% - 80px) !important;
  }
  .card-table-header:deep(.table-header-field) {
    margin-left: 60px !important;
  }
}
</style>

<style lang="scss">
@import '../../../../assets/styleVars.scss';

.deals-list {
  width: 100%;
  height: calc(100% - 204px);
  overflow-y: scroll;

  .ghost {
    z-index: 999;
    opacity: 0.9;
    background: $appActionColor !important;
  }

  .chosen-item {
    background: $whiteColor;
  }
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
