<template>
  <div class="main-header">
    <div class="header-row">
      <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
      <UserInfoHeaderSection :username="currentUser.full_name" />
    </div>
    <div class="header-row">
      <div class="title-section">
        <div class="title-container">
          <div class="title">
            Pipeline Value
            {{ formatPipelineValue(activePipeline.projected_value) }}
          </div>
          <div class="current-date">{{ getPipelineDate() + 'Pipeline' }}</div>
        </div>
      </div>
      <div class="vertical-divider"></div>
      <div class="closed-value-container">
        Closed Value {{ formatPipelineValue(activePipeline.closed_value) }}
      </div>
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <UndoRedoBtns
          :isUndoStackActive="isUndoStackActive"
          :isRedoStackActive="isRedoStackActive"
          @undo="Undo()"
          @redo="Redo()"
        />
        <MoreActionsBtn
          :width="'44px'"
          :height="'46px'"
          :isActive="isActionMenuShown"
          @click="openHeaderActionMenu"
        />
        <ReportsButton
          :width="'44px'"
          :height="'46px'"
          @click="getReportsForActivePipeline"
        />
        <ActionMenu
          :width="'280px'"
          :height="'49px'"
          :top="'50px'"
          :left="'100px'"
          :isActionMenuShown="isActionMenuShown"
          @closeActionMenu="closeHeaderActionMenu"
        >
          <ActionMenuItem
            v-for="(action, index) in Object.values(menuActions)"
            :key="index"
            :text="action"
            @actionMenuItemClick="handleActionMenuClick"
          />
        </ActionMenu>
      </div>
      <div class="right-side-actions">
        <ActionButton
          :width="'126px'"
          :height="'46px'"
          :text="'Import'"
          :class="{ 'import-btn': true }"
          @click="$emit('showImportModal')"
        />
        <div class="buttons-divider"></div>
        <ActionButton
          :width="'210px'"
          :height="'46px'"
          :text="'+ Add new prospect'"
          :class="{
            'inactive-btn': !isSubscriptionActive,
            'add-customer-btn': true,
          }"
          @click="showModal"
        />
      </div>
      <ProspectsActionModal
        :isModalShown="isModalShown"
        :isModalLoading="isModalLoading"
        :modalError="modalError"
        :newProspectName="newProspectName"
        :newProspectCompany="newProspectCompany"
        :newProspectStatus="newProspectStatus"
        @hideModal="hideModal"
        @setNewProspectName="setNewProspectName"
        @setNewProspectCompany="setNewProspectCompany"
        @setNewProspectStatus="setNewProspectStatus"
        @createProspect="handleCreateProspectAndDeal"
      />
    </div>
    <div class="header-row">
      <div class="goal-values">
        <PipelineGoalField
          :value="activePipeline.goal_one"
          :placeholder="'Monthly Revenue Goal'"
          :isSubscriptionActive="isSubscriptionActive"
          @pipelineGoalUpdated="handlePipelineGoalsUpdate({ goal_one: $event })"
        />
        <PipelineGoalField
          :value="activePipeline.goal_two"
          :placeholder="'Confidence Level %'"
          :isSubscriptionActive="isSubscriptionActive"
          @pipelineGoalUpdated="handlePipelineGoalsUpdate({ goal_two: $event })"
        />
        <PipelineGoalField
          :value="activePipeline.goal_three"
          :placeholder="'Non-revenue Goal'"
          :isSubscriptionActive="isSubscriptionActive"
          @pipelineGoalUpdated="
            handlePipelineGoalsUpdate({ goal_three: $event })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import UserInfoHeaderSection from '../../../common/header/UserInfoHeaderSection.vue'
import UndoRedoBtns from '../../../common/header/UndoRedoBtns.vue'
import ActionButton from '../../../common/ActionButton.vue'
import ActionMenu from '../../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../../common/actionMenu/ActionMenuItem.vue'
import ProspectsActionModal from '../../../common/ProspectsActionModal.vue'
import MoreActionsBtn from '../../../common/MoreActionsBtn.vue'
import PipelineGoalField from '../../../common/mainContent/PipelineGoalField.vue'
import ReportsButton from '../../../common/ReportsButton.vue'

import { getPipelineDate } from '../../../../utils/helpers'
import { ProspectStatus } from '../../../../utils/constants'

export default {
  emits: ['showImportModal', 'openSidebar'],
  props: ['selectedRows', 'isSubscriptionActive'],
  components: {
    UserInfoHeaderSection,
    UndoRedoBtns,
    ActionButton,
    ActionMenu,
    ActionMenuItem,
    ProspectsActionModal,
    MoreActionsBtn,
    PipelineGoalField,
    ReportsButton,
  },
  data() {
    return {
      isActionMenuShown: false,
      isLoading: false,
      modalError: '',
      isModalShown: false,
      isModalLoading: false,
      newProspectName: '',
      newProspectCompany: '',
      newProspectStatus: 'Target',
      isStatusDropdownOpened: false,
      prospectStatus: ProspectStatus,
      dealsDeletionErrors: {
        hot: '',
        inProgress: '',
        target: '',
      },
      menuActions: {
        REMOVE_FROM_PIPELINE: 'Remove from pipeline',
      },
    }
  },
  computed: {
    ...mapState([
      'currentUser',
      'activePipeline',
      'isUndoStack',
      'isRedoStack',
    ]),
    isUndoStackActive() {
      return this.isUndoStack
    },
    isRedoStackActive() {
      return this.isRedoStack
    },
  },
  methods: {
    ...mapActions([
      'getActivePipeline',
      'getNextHotDealsPage',
      'getNextTargetDealsPage',
      'getNextInProgressDealsPage',
      'updatePipelineGoals',
      'createProspectAndDeal',
      'removeHotDeals',
      'removeInProgressDeals',
      'removeTargetDeals',
      'clearAllProspectDeals',
      'getMonthReportHtml',
      'handelUndoRedoClick',
    ]),
    getPipelineDate,
    formatPipelineValue(value) {
      if (value && value > 0) {
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '$0'
    },
    async handlePipelineGoalsUpdate(goalUpdate) {
      const goals = {
        goal_one: this.activePipeline.goal_one,
        goal_two: this.activePipeline.goal_two,
        goal_three: this.activePipeline.goal_three,
      }
      const payload = { ...goals, ...goalUpdate }

      await this.updatePipelineGoals(payload)
    },
    async Undo() {
      await this.handelUndoRedoClick('undoStack')
      await this.clearAllProspectDeals()

      let payload = {
        order: this.order,
        pageSize: this.pageSize,
        searchTerm: this.searchTerm,
        pageNumber: this.currentPageNumber,
        dealsType: '',
      }

      payload.dealsType = this.dealsType.HotProspectDeal
      await this.getNextHotDealsPage(payload)

      payload.dealsType = this.dealsType.TargetProspectDeal
      await this.getNextTargetDealsPage(payload)

      payload.dealsType = this.dealsType.InProgressProspectDeal
      await this.getNextInProgressDealsPage(payload)
    },
    async Redo() {
      await this.handelUndoRedoClick('redoStack')
      await this.clearAllProspectDeals()
      let payload = {
        order: this.order,
        pageSize: this.pageSize,
        searchTerm: this.searchTerm,
        pageNumber: this.currentPageNumber,
        dealsType: '',
      }
      payload.dealsType = this.dealsType.HotProspectDeal
      await this.getNextHotDealsPage(payload)
      payload.dealsType = this.dealsType.TargetProspectDeal
      await this.getNextTargetDealsPage(payload)
      payload.dealsType = this.dealsType.InProgressProspectDeal
      await this.getNextInProgressDealsPage(payload)
    },
    getReportsForActivePipeline() {
      this.getMonthReportHtml(this.activePipeline.id)
    },
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    openHeaderActionMenu() {
      if (!this.isSubscriptionActive) return
      this.isActionMenuShown = true
    },
    hideModal() {
      this.modalError = ''
      this.isModalShown = false
    },
    showModal() {
      if (!this.isSubscriptionActive) return
      this.isModalShown = true
    },
    setNewProspectName(newProspectName) {
      this.newProspectName = newProspectName
    },
    setNewProspectCompany(newProspectCompany) {
      this.newProspectCompany = newProspectCompany
    },
    setNewProspectStatus(statusName) {
      this.isStatusDropdownOpened = false
      this.newProspectStatus = statusName
    },
    closeHeaderActionMenu() {
      this.isActionMenuShown = false
    },
    async handleCreateProspectAndDeal() {
      if (!this.newProspectName || !this.newProspectCompany) {
        this.modalError = 'Prospect name and company are required'
        return
      }

      const payload = {
        new_prospect: {
          name: this.newProspectName,
          company: this.newProspectCompany,
          status: this.prospectStatus[this.newProspectStatus],
        },
      }

      try {
        this.isModalLoading = true

        await this.createProspectAndDeal(payload)

        this.isModalShown = false
        this.newProspectName = ''
        this.newProspectCompany = ''
      } catch (error) {
        console.log('==== error while creating prospect: ', error)
        this.modalError = 'Something went wrong, please try again'
      } finally {
        this.isModalLoading = false
      }
    },
    async handleActionMenuClick() {
      this.isActionMenuShown = false
      this.isLoading = true

      let isIdsLength = Object.values(this.selectedRows).find((i) => i.length)

      if (!isIdsLength) {
        this.isLoading = false
        return
      }

      const { hot, inProgress, target } = this.selectedRows
      const promises = []

      hot.length && promises.push(this.removeHotDeals(hot))
      inProgress.length && promises.push(this.removeInProgressDeals(inProgress))
      target.length && promises.push(this.removeTargetDeals(target))

      try {
        await Promise.allSettled(promises)
        this.selectedRows.hot = []
        this.selectedRows.inProgress = []
        this.selectedRows.target = []
      } catch (error) {
        console.log('==== error while removing prospect deals: ', error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.header-row {
  width: 95%;
  margin: 5px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  color: $fontColorBlack;
  overflow-x: scroll;

  .action-button {
    margin-top: 0;
  }

  .goal-values {
    display: flex;
    flex-direction: row !important;
    width: 60%;

    .pipeline-goal-field {
      width: 144px !important;
    }

    .pipeline-goal-field:deep(.input-field) {
      width: 110px !important;
    }
  }

  .sidebar-btn {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/hamburger-icon.svg');
  }

  .vertical-divider {
    width: 1px;
    height: 73px;
    margin-right: 15px;
    background-color: #c9cde8;
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }

      .current-date {
        font-size: $fontSizeMinified;
        color: $fontColorGray;
      }
    }

    .closed-value-container {
      margin-left: 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .left-side-actions {
    width: 50%;
    position: relative;
    display: flex;
    margin-right: 10px;
  }

  .right-side-actions {
    position: relative;
    display: flex;

    .buttons-divider {
      width: 10px;
    }

    .action-button {
      width: 46px !important;
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      text-indent: -9999px;
      cursor: pointer;
    }

    .add-customer-btn {
      background-image: url('../../../../assets/icons/add-icon.svg');
    }

    .import-btn {
      background-image: url('../../../../assets/icons/import-icon-white.svg');
    }
  }

  .modal-header {
    width: calc(100% - 80px);
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      font-size: $fontSizeModalTitle;
    }

    .close-modal-btn {
      width: 24px;
      height: 24px;
      background-size: 14px;
      background-repeat: no-repeat;
      background-image: url('../../../../assets/icons/close-icon.svg');
      cursor: pointer;
    }
  }

  .modal-error {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: $redWarningColor;
    font-size: $fontSizeMinified;
  }

  .modal-content {
    width: calc(100% - 180px);
    height: 158px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin-bottom: 30px;

    .action-button {
      width: 120px;
    }
  }
}
</style>
