<template>
  <MainCard>
    <MainCardHeader>
      <div class="card-header-row">
        <div class="card-title">
          Hot Prospects
          <InfoBox
            text="New business that you expect sales from within the next 30 days. 90% of the value is added to your pipeline."
          />
        </div>
        <SearchField
          @search="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
      </div>
      <CardTableHeader
        :isCheckboxShown="true"
        :isCompanyShown="true"
        :isValueShown="true"
        :isDealsStatusShown="true"
        :isSelectAllCheckbox="isSelectAllCheckbox"
        :selectedRows="selectedRows"
        :order="order"
        @checkboxSelect="selectAllRows"
        @removeSelection="removeRowsSelection"
        @setOrder="setOrder"
      />
    </MainCardHeader>
    <DividerLine />
    <div
      class="deals-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <draggable
        v-model="currentPage"
        handle=".drag-handle"
        ghost-class="ghost"
        chosen-class="chosen-item"
        :disabled="order !== '' || isDragDisabled"
        @end="handleDragEnd"
        item-key="id"
      >
        <template #item="{ element }">
          <PipelineCardRow
            :key="element.id"
            :id="element.id"
            :order="order"
            :isCompanyEditable="true"
            :isNameEditable="true"
            :name="element.subject_name"
            :company="element.subject_company"
            :dealValue="element.value"
            :selectedRows="selectedRows"
            :isDealClosed="element.is_closed"
            :menuActions="Object.values(menuActions)"
            :actionMenuWidth="'282px'"
            :actionMenuHeight="'245px'"
            :actionMenuTop="'45px'"
            :isSubscriptionActive="isSubscriptionActive"
            :isOutOfReportRow="isItemOutOfReport(element.id)"
            @updateName="
              handleDealSubjectUpdate({ subject_name: $event }, element)
            "
            @updateCompany="
              handleDealSubjectUpdate({ subject_company: $event }, element)
            "
            @changeDealClosedState="
              handleDealStatusChange(
                element.is_closed,
                element.value,
                element.id
              )
            "
            @updateDealValue="handleDealValueUpdate($event, element.id)"
            @checkboxSelect="selectOneRow(element.id)"
            @removeSelection="removeOneRowSelection(element.id)"
            @actionMenuItemClick="handleActionMenuClick($event, element)"
          />
        </template>
      </draggable>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

import MainCard from '../../../common/mainContent/MainCard.vue'
import MainCardHeader from '../../../common/mainContent/MainCardHeader.vue'
import CardTableHeader from '../../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../../common/DividerLine.vue'
import SearchField from '../../../common/header/SearchField.vue'
import Spinner from '../../../common/Spinner.vue'
import CardFooter from '../../../common/mainContent/CardFooter.vue'
import ActionMenu from '../../../common/actionMenu/ActionMenu.vue'
import InfoBox from '../../../common/InfoBox.vue'
import ActionMenuItem from '../../../common/actionMenu/ActionMenuItem.vue'
import PipelineCardRow from '../PipelineCardRow.vue'

import { DealType } from '../../../../utils/constants'

export default {
  props: [
    'selectedRows',
    'isSubscriptionActive',
    'shouldUpdateHotList',
    'shouldResetHotOrder',
  ],
  components: {
    draggable,
    MainCard,
    DividerLine,
    CardTableHeader,
    SearchField,
    MainCardHeader,
    Spinner,
    CardFooter,
    ActionMenu,
    ActionMenuItem,
    PipelineCardRow,
    InfoBox,
  },
  data() {
    return {
      isLoading: true,
      pageSize: 'All',
      currentPageNumber: 1,
      searchTerm: '',
      order: '',
      isDragDisabled: false,
      isActionMenuShown: false,
      errorMessage: '',
      isSelectAllCheckbox: false,
      menuActions: {
        MOVE_TO_CLIENTS: 'Move to Clients',
        MOVE_TO_IN_PROGRESS: 'Move to in Progress',
        MOVE_TO_TARGET: 'Move to Target',
        REMOVE_FROM_PIPELINE: 'Remove from Pipeline',
        ARCHIVE: 'Archive',
      },
      dealsType: DealType,
      RowsPerPage: ['All', 10, 20, 50],
    }
  },
  async created() {
    await this.getFirstPage()
  },
  computed: {
    ...mapState(['hotDeals']),
    hotDealsList() {
      return this.hotDeals.hotDealsList
    },
    currentPage: {
      get() {
        if (this.pageSize === 'All') return this.hotDealsList

        const start =
          this.currentPageNumber === 1
            ? 0
            : this.currentPageNumber * this.pageSize - this.pageSize

        const stop =
          this.currentPageNumber === 1
            ? this.pageSize
            : this.currentPageNumber * this.pageSize

        const page = this.hotDealsList.slice(start, stop)

        return page
      },
      set(value) {},
    },
    totalItemsCount() {
      return this.hotDeals.hotDealsCount
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All') return this.hotDealsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    async shouldUpdateHotList(newValue, _) {
      if (!newValue) return

      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearHotDeals()
      await this.getFirstPage()

      this.$emit('hotListUpdated')
    },
    modalError(newValue, _) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000)
      }
    },
    async order() {
      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearHotDeals()
      await this.getFirstPage()
    },
    shouldResetHotOrder(newValue, _) {
      if (!newValue) return

      this.order = ''
      this.$emit('hotOrderResetted')
    },
  },
  methods: {
    ...mapActions([
      'getNextHotDealsPage',
      'updateDealsOrder',
      'updateProspectDealSubject',
      'updateHotDealValue',
      'openHotDeal',
      'closeHotDeal',
      'moveHotDealToInProgress',
      'moveHotDealToTarget',
      'removeHotDeals',
      'searchHotPropspectDeals',
      'clearHotDeals',
      'movePipelineProspectsToClients',
      'archiveProspects',
    ]),
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.clearHotDeals()
      await this.getFirstPage()
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue
      this.currentPageNumber = 1
      const payload = {
        order: this.order,
        searchTerm: searchValue,
        pageSize: this.pageSize,
        pageNumber: this.currentPageNumber,
        dealsType: this.dealsType.HotProspectDeal,
      }

      try {
        await this.searchHotPropspectDeals(payload)
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },
    handleGeneralError(error) {
      console.log('==== error in hot deals: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    async getFirstPage() {
      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.HotProspectDeal,
        }
        await this.getNextHotDealsPage(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    setOrder(evt) {
      if (this.isLoading) return
      this.order = evt
    },
    async handleDragEnd(evt) {
      this.isDragDisabled = true

      const { oldIndex, newIndex } = evt

      if (oldIndex === newIndex) return

      const elementId = this.currentPage[oldIndex].id

      try {
        await this.updateDealsOrder({
          elementId,
          oldLocalIndex: oldIndex,
          newLocalIndex: newIndex,
          dealsType: this.dealsType.HotProspectDeal,
        })
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isDragDisabled = false
      }
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.currentPageNumber += 1
      this.$emit('removeAllDealsSelection')

      if (this.currentPage.length) return

      this.isLoading = true

      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.HotProspectDeal,
        }
        await this.getNextHotDealsPage(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.$emit('removeAllDealsSelection')
      this.currentPageNumber -= 1
    },
    isItemOutOfReport(itemId) {
      const itemIndex = this.hotDealsList.findIndex((i) => i.id === itemId)

      if (itemIndex > 29) return true

      return false
    },
    selectAllRows() {
      const ids = this.currentPage.map((item) => item.id)
      this.$emit('selectAllDeals', ids)
    },
    removeRowsSelection() {
      this.$emit('removeAllDealsSelection')
    },
    selectOneRow(rowId) {
      this.$emit('selectOneDeal', rowId)
      if (this.selectedRows.length === this.currentPage.length) {
        this.isSelectAllCheckbox = true
      }
    },
    removeOneRowSelection(rowId) {
      this.$emit('removeOneDealSelection', rowId)

      if (this.selectedRows.length < 1) {
        this.actionActive = false
      }
      if (this.selectedRows.length < this.currentPage.length) {
        this.isSelectAllCheckbox = false
      }
    },
    handleDealSubjectUpdate(dealSubjectUpdate, deal) {
      try {
        const updatedDeal = { ...deal, ...dealSubjectUpdate }
        this.updateProspectDealSubject(updatedDeal)
      } catch (error) {
        this.handleGeneralError(error)
      }
    },
    async handleDealValueUpdate(newDealValue, dealId) {
      const dealUpdate = { value: Number(newDealValue), id: dealId }
      await this.updateHotDealValue(dealUpdate)
    },
    async handleDealStatusChange(isDealClosed, dealValue, dealId) {
      const payload = { id: dealId, value: Number(dealValue) }

      if (isDealClosed) {
        await this.openHotDeal(payload)
      } else {
        await this.closeHotDeal(payload)
      }
    },
    async handleActionMenuClick(action, deal) {
      this.isActionMenuShown = false
      this.isLoading = true

      const payload = {
        prospectId: deal.subject,
        dealId: deal.id,
        name: deal.subject_name,
        company: deal.subject_company,
      }

      try {
        if (action === this.menuActions.MOVE_TO_CLIENTS) {
          await this.movePipelineProspectsToClients([deal.subject])
        }

        if (action === this.menuActions.MOVE_TO_IN_PROGRESS) {
          payload.status = this.dealsType.InProgressProspectDeal
          await this.moveHotDealToInProgress(payload)
        }

        if (action === this.menuActions.MOVE_TO_TARGET) {
          payload.status = this.dealsType.TargetProspectDeal
          await this.moveHotDealToTarget(payload)
        }

        if (action === this.menuActions.REMOVE_FROM_PIPELINE) {
          await this.removeHotDeals([deal.id])
          this.$emit('updateProspectsList')
        }

        if (action === this.menuActions.ARCHIVE) {
          await this.removeHotDeals([deal.id])
          await this.archiveProspects([deal.subject])

          this.$emit('updateProspectsList')
        }

        this.currentPageNumber = 1

        await this.clearHotDeals()
        await this.getFirstPage()

        this.removeRowsSelection()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.main-card {
  height: 655px;
  min-height: 655px;
  margin-top: 175px;

  .card-header-row {
    width: calc(100% - 80px);
    height: calc(100% - 47px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
      font-size: $fontSizeTitle;
    }
  }
}

@media (max-width: 420px) {
  .main-card {
    margin-top: 260px !important;
  }

  .card-footer {
    width: 980px;
  }

  .card-footer:deep(.rows-per-page) {
    margin-left: 340px !important;
  }

  .divider-line {
    width: 980px;
  }

  .deals-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    overflow-y: visible;
  }

  .card-table-header {
    width: calc(100% - 80px) !important;
  }

  .card-table-header:deep(.table-header-field) {
    margin-left: 60px !important;
  }
}
</style>

<style lang="scss">
@import '../../../../assets/styleVars.scss';

.deals-list {
  width: 100%;
  height: calc(100% - 204px);
  overflow-y: scroll;

  .ghost {
    z-index: 999;
    opacity: 0.9;
    background: $appActionColor !important;
  }

  .chosen-item {
    background: $whiteColor;
  }
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
