<template>
  <ActionModal
    v-if="isModalShown"
    :width="'440px'"
    :height="'336px'"
    :isModalShown="isModalShown"
  >
    <div class="modal-header">
      <div class="modal-title">Enter deal value</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="modal-error">{{modalError}}</div>
    <div class="modal-content">
      <div class="description">Every prospect requires a dollar value. Please enter an amount here</div>
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :placeholder="'Enter deal value'"
        :value="newDealValue"
        :autofocus="true"
        @updateValue="$emit('setNewDealValue', $event)"
      />
    </div>
    <ActionButton
      :width="'350px'"
      :height="'54px'"
      :text="buttonTitle"
      @click="$emit('addToPipeline')"
    />
  </ActionModal>
</template>

<script>
import ActionModal from '../../../common/ActionModal.vue'
import CommonInputField from '../../../common/CommonInputField.vue'
import ActionButton from '../../../common/ActionButton.vue'

export default {
  props: [
    'isModalShown',
    'buttonTitle',
    'newDealValue',
    'modalError'
  ],
  components: {
    ActionModal,
    CommonInputField,
    ActionButton,
  },

}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>